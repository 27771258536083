// Constants

import { NavItemData, Skill } from "./types";

export const TECH = {
  C: 'C',
  CPP: 'C++',
  CSHARP: 'C#',
  PYTHON: 'Python',
  JS: 'Javascript',
  TS: 'Typescript',
  HTML: 'HTML',
  CSS: 'CSS',
  JAVA: 'Java',
  RUST: 'Rust',
  SQL: 'SQL',
  GQL: 'GraphQL',
  GLSL: 'GLSL',
  OPENGL: 'OpenGL',
  WEBGL: 'WebGL',
  ANGULAR: 'Angular',
  REACT: 'React',
  RN: 'React Native',
  UE: 'UnityEngine',
  SWING: 'Java Swing',
  JDBC: 'JDBC',
  TFLOW: 'TensorFlow',
  FLASK: 'Flask',
  NODE: 'Node.js',
  AWS: 'AWS',
  AWS_COGNITO: 'AWS Cognito',
  AWS_APPSYNC: 'AWS AppSync',
  AWS_DB: 'AWS DynamoDB',
  FB: 'Firebase',
  FB_FIRESTORE: 'Cloud Firestore',
  FB_AUTH: 'Firebase Auth',
  MONGO: 'MongoDB',
  ORACLE: 'Oracle Database',
  TK: 'Tkinter',
  VSC: 'VSCode API',
  RXJS: 'RxJS',
  WIN: 'Windows',
  LINUX: 'Linux',
  GIT: 'Git',
  CUDA: 'CUDA',
  JEST: 'Jest'
}

export const TECH_LOGOS = {
  C: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533350/logos/c_wgfk2d.svg',
  CPP: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533373/logos/c-plusplus_glynll.svg',
  CSHARP: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533361/logos/c-sharp_ktjulq.svg',
  PYTHON: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533339/logos/python_dyuo5d.svg',
  JS: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610532875/logos/javascript_dixzg7.svg',
  TS: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533318/logos/typescript-icon_y0i2ud.svg',
  HTML: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550998/logos/html-5_gohsco.svg',
  CSS: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551010/logos/css-3_zyl2sa.svg',
  JAVA: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533329/logos/java_yff0bb.svg',
  RUST: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550929/logos/rust_chppkg.svg',
  SQL: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533150/logos/sql-svgrepo-com_qixtji.svg',
  GQL: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533308/logos/graphql_uwkp9k.svg',
  GLSL: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533296/logos/opengl_biepn6.svg',
  OPENGL: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533296/logos/opengl_biepn6.svg',
  WEBGL: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550643/logos/WebGL_Logo_f2dnig.svg',
  ANGULAR: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610532322/logos/angular-icon_t0fohq.svg',
  REACT: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550914/logos/react_ymnjf0.svg',
  RN: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550914/logos/react_ymnjf0.svg',
  UE: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550866/logos/unity_svsur3.svg',
  SWING: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533329/logos/java_yff0bb.svg',
  JDBC: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533329/logos/java_yff0bb.svg',
  TFLOW: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550810/logos/tensorflow_kywscs.svg',
  FLASK: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550824/logos/flask_qwf4bx.svg',
  NODE: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550838/logos/nodejs-icon_rgpl1v.svg',
  AWS: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551654/logos/aws_pttct2.svg',
  AWS_COGNITO: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551772/logos/aws-cognito_wiivsg.svg',
  AWS_APPSYNC: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551329/logos/aws-api-gateway_reyun2.svg',
  AWS_DB: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551750/logos/aws-dynamodb_efcjos.svg',
  FB: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551516/logos/firebase_fq1w9a.svg',
  FB_FIRESTORE: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551516/logos/firebase_fq1w9a.svg',
  FB_AUTH: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551516/logos/firebase_fq1w9a.svg',
  MONGO: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551540/logos/mongodb_nvluto.svg',
  ORACLE: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551561/logos/oracle_ckjkzt.svg',
  TK: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610533339/logos/python_dyuo5d.svg',
  VSC: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610557142/logos/visual-studio-code_p9wqkl.svg',
  RXJS: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610550849/logos/reactivex_ywwc70.svg',
  WIN: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551614/logos/microsoft-windows_urmkjz.svg',
  LINUX: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551632/logos/linux-tux_f2w2v5.svg',
  GIT: 'https://res.cloudinary.com/djxztokzp/image/upload/v1610551581/logos/git-icon_vrosdp.svg',
  JEST: 'https://res.cloudinary.com/djxztokzp/image/upload/v1612726193/logos/jest_ufrjxk.svg',
}

export const languages: Skill[] = [
  { name: TECH.JS, level: 3, logoUrl: TECH_LOGOS.JS },
  { name: TECH.TS, level: 3, logoUrl: TECH_LOGOS.TS },
  { name: TECH.JAVA, level: 2, logoUrl: TECH_LOGOS.JAVA },
  { name: TECH.PYTHON, level: 3, logoUrl: TECH_LOGOS.PYTHON },
  { name: TECH.C, level: 0, logoUrl: TECH_LOGOS.C },
  { name: TECH.CPP, level: 2, logoUrl: TECH_LOGOS.CPP },
  { name: TECH.CSHARP, level: 1, logoUrl: TECH_LOGOS.CSHARP },
  { name: TECH.HTML, level: 3, logoUrl: TECH_LOGOS.HTML },
  { name: TECH.CSS, level: 3, logoUrl: TECH_LOGOS.CSS },
  { name: TECH.RUST, level: 0, logoUrl: TECH_LOGOS.RUST },
  { name: TECH.SQL, level: 0, logoUrl: TECH_LOGOS.SQL },
  { name: TECH.GQL, level: 0, logoUrl: TECH_LOGOS.GQL },
  { name: TECH.GLSL, level: 0, logoUrl: TECH_LOGOS.GLSL },
];

export const languages1 = [
  TECH.JS,
  TECH.TS,
  TECH.JAVA,
  TECH.PYTHON,
  TECH.C,
  TECH.CPP,
  TECH.CSHARP,
  TECH.HTML,
  TECH.CSS,
  TECH.RUST,
  TECH.SQL,
  TECH.GQL,
  TECH.GLSL,
];

export const libraries: Skill[] = [
  { name: TECH.ANGULAR, level: 3, logoUrl: TECH_LOGOS.ANGULAR },
  { name: TECH.REACT, level: 3, logoUrl: TECH_LOGOS.REACT },
  { name: TECH.OPENGL, level: 2, logoUrl: TECH_LOGOS.OPENGL },
  { name: TECH.WEBGL, level: 3, logoUrl: TECH_LOGOS.WEBGL },
  { name: TECH.RN, level: 1, logoUrl: TECH_LOGOS.RN },
  { name: TECH.UE, level: 2, logoUrl: TECH_LOGOS.UE },
  { name: TECH.SWING, level: 1, logoUrl: TECH_LOGOS.SWING },
  { name: TECH.JDBC, level: 1, logoUrl: TECH_LOGOS.JDBC },
  { name: TECH.TFLOW, level: 1, logoUrl: TECH_LOGOS.TFLOW },
  { name: TECH.FLASK, level: 0, logoUrl: TECH_LOGOS.FLASK },
  { name: TECH.NODE, level: 2, logoUrl: TECH_LOGOS.TK },
  { name: TECH.RXJS, level: 3, logoUrl: TECH_LOGOS.RXJS },
  { name: TECH.JEST, level: 1, logoUrl: TECH_LOGOS.JEST },
];

export const libraries1 = [
  TECH.ANGULAR,
  TECH.REACT,
  TECH.OPENGL,
  TECH.WEBGL,
  TECH.RN,
  TECH.UE,
  TECH.SWING,
  TECH.JDBC,
  TECH.TFLOW,
  TECH.FLASK,
  TECH.NODE,
  TECH.TK,
  TECH.RXJS,
];

export const tools: Skill[] = [
  { name: TECH.GIT, level: 3, logoUrl: TECH_LOGOS.GIT },
  { name: TECH.WIN, level: 3, logoUrl: TECH_LOGOS.WIN },
  { name: TECH.LINUX, level: 3, logoUrl: TECH_LOGOS.LINUX },
  // { name: TECH.AWS, level: 1, logoUrl: TECH_LOGOS.AWS },
  { name: TECH.AWS_APPSYNC, level: 1, logoUrl: TECH_LOGOS.AWS_APPSYNC },
  { name: TECH.AWS_COGNITO, level: 2, logoUrl: TECH_LOGOS.AWS_COGNITO },
  { name: TECH.AWS_DB, level: 2, logoUrl: TECH_LOGOS.AWS_DB },
  // { name: TECH.FB, level: 1, logoUrl: TECH_LOGOS.FB },
  { name: TECH.FB_AUTH, level: 3, logoUrl: TECH_LOGOS.FB_AUTH },
  { name: TECH.FB_FIRESTORE, level: 3, logoUrl: TECH_LOGOS.FB_FIRESTORE },
  { name: TECH.MONGO, level: 2, logoUrl: TECH_LOGOS.MONGO },
  { name: TECH.ORACLE, level: 1, logoUrl: TECH_LOGOS.ORACLE },
];

export const tools1 = [
  TECH.GIT,
  TECH.WIN,
  TECH.LINUX,
  TECH.AWS,
  TECH.AWS_APPSYNC,
  TECH.AWS_COGNITO,
  TECH.AWS_DB,
  TECH.FB,
  TECH.FB_AUTH,
  TECH.FB_FIRESTORE,
  TECH.MONGO,
  TECH.ORACLE,
];

export const TAGS = {
  ...TECH,
  WEB: 'web app',
  DESKTOP: 'desktop app',
  GP: 'graphics programming',
  G: 'graphics',
  THREED: '3D',
  GAME: 'game',
  DBMS: 'DBMS',
  DB: 'database',
  IR: 'information retrieval',
  OOP: 'object-oriented programming',
  FUNC: 'functional programming',
  SCRIPT: 'scripting',
  ML: 'ML',
  CV: 'computer vision',
  PACKAGE: 'package',
  EXT: 'extension',
  FB: 'Firebase',
  AWS: 'AWS',
  SERVERLESS: 'serverless',
}

export const STATUSES = {
  live: 'live',
  wip: 'coming soon',
  comp: 'completed, private',
}

export const navItems: NavItemData[] = [
  { text: 'home', iconSrc: require('../assets/icon_home.svg'), link: '/' },
  { text: 'about', iconSrc: require('../assets/icon_about.svg'), link: '/about' },
  { text: 'projects', iconSrc: require('../assets/icon_projects.svg'), link: '/projects' },
  { text: 'resume', iconSrc: require('../assets/icon_skills.svg'), link: require('../assets/pdfs/Resume_Duha_Hassan_v3_1_default.pdf'), external: true, },
];

export const contactLinks: NavItemData[] = [
  { text: 'email', iconSrc: require('../assets/icon_contact.svg'), link: 'mailto:duha.h.153@gmail.com', external: true, },
  { text: 'github', iconSrc: require('../assets/icon_github.svg'), link: 'https://github.com/Duha-H', external: true, },
  { text: 'linkedin', iconSrc: require('../assets/icon_linkedin.svg'), link: 'https://linkedin.com/in/duha-hassan/', external: true, },
];
